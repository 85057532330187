* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 14px;
}

#root {
  height: inherit;
  background-color: #1e1e1e;
  padding: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
label {
  font-family: Raleway, sans-serif;
  color: #000;
}

p {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a,
label {
  color: #04012D;
}

a,
label {
  font-weight: 400;
  font-size: 18px;
}

ol,
p,
ul {
  padding: 0;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.p-6 {
  padding: "6px";
}

.btn {
  font-size: 14px;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.main-container {
  padding: 90px 20px 0 300px;
}

.main-container .pd-ltr-20 {
  padding: 0px 20px;
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .main-container {
    padding-left: 270px;
  }
}

@media (max-width: 1300px) {
  .main-container {
    padding: 100px 0px 10px 0px;
  }
}

/* @media (max-width: 1300px) {
  .main-container {
      padding-left: 0;
      padding-right: 0;
  }
} */


@media (max-width: 1024px) {
  .main-container {
    padding: 100px 20px 0 0px;
  }
}

@media (max-width: 767px) {
  .main-container {
    padding: 90px 0px 0px 0px;
  }
}

.btn-orange {
  background-color: #FF551F;
  color: #fff;
  padding: 4px 16px;
}

/* =========== Login ===============*/

.border-radius-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.main {
  border-radius: 34px;
  background-color: #F6F9FC;
  padding: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.login-page {
  background-image: url("/src/assets/images/login-page-img.png");
  border-radius: 25px;
  /* width: 100%; 
  height: 99vh;  */
  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;

}

.login-wrap {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1e1e2c 100%);
  border-radius: 25px;
  height: inherit;
  padding: 0;
}


.login-box {
  padding: 40px 40px;
}

.content-container {
  position: relative;
  height: 99vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1e1e2c 100%);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.liner {
  display: inline-block;
  border: 3px #FF551F solid;
  border-radius: 25px;
  margin-right: 28px;
  height: 100px;
}

.main-logo {
  aspect-ratio: 0.06;
  object-fit: auto;
  object-position: center;
  width: 6px;
  stroke-width: 6px;
  stroke: #ff551f;
  border-color: rgba(255, 85, 31, 1);
  border-style: solid;
  border-width: 6px;
  align-self: start;
}

.tagline {
  display: flex;
  align-items: center;
}

.tagline>h2 {
  font-size: 48px;
  font-weight: 400;
}

.login-form-container label {
  color: #65707e;
  font-size: 18px;
}

.login-form-container label#chk {
  color: #04012D;
  font-weight: 400;
  font-size: 18px;
}

.login-form-section {
  flex: 1;
  flex-direction: column;
  line-height: normal;
}

.welcome-text {
  margin-top: 32px;
  font: 700 33px Inter, sans-serif;
}

.instruction-text {
  color: #65707e;
  font-family: Raleway, sans-serif;
  margin-top: 6px;
  margin-bottom: 21px;
  font-size: 18px;
}

.login-button {
  text-align: center;
  font-family: Poppins, sans-serif;
  border-radius: 11.049px;
  box-shadow: 0px 1.381px 2.762px 0px rgba(16, 24, 40, 0.05);
  background-color: #0f8bfd;
  margin-top: 42px;
  align-items: center;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  justify-content: center;
  padding: 23px 60px;
}

.login-form-container {
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 19px;
  color: #0a1524;
  font-weight: 500;
  padding: 30px 50px;
  /* width: 550px; */

}

.login-logo {
  height: 50px;
}

.forgot-password-link {
  font-weight: 400;
  font-size: 18px;
}

.group-input-button {
  border: 1px solid #d4d4d4;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.group-input-field {
  border-right: 0;
  font-size: 14px;
}

.custom-file-input:focus,
.custom-select:focus,
.form-control:focus {
  color: #131e22;
  border-color: #0f8bfd;
  -webkit-box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
}

.focus .group-input-button {
  border-color: #0f8bfd;
}

.customization {
  color: #0f8bfd;
  font-size: 20px !important;
}

.customization1 {
  color: #495057;
  font-size: 20px !important;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {} */
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .tagline {
    width: 600px;
    /* margin-top: 20px; */
  }

  .tagline>h2 {
    font-size: 38px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 768px) and (max-width: 1023px) {

  .liner {
    margin-right: 24px;
    height: 60px;
  }

  .login-logo-container {
    font-size: 40px;
  }

  .login-button {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
    padding: 0 20px;
  }

  .login-form-section {
    margin-left: 0;
    margin-bottom: 40px;
  }

  .tagline {
    width: 400px;
    margin-top: 20px;
  }

  .tagline>h2 {
    font-size: 30px;
  }

  .login-form-container {
    padding: 12px 24px;
    /* width: 500px; */
  }

  .instruction-text {
    font-size: 16px;
  }

  .welcome-text {
    margin-top: 25px;
    font: 700 24px Inter, sans-serif;
  }

  .login-box {
    padding: 35px;
  }

  .login-box label#chk {
    font-size: 18px;
  }

  .forgot-password-link {
    font-size: 16px;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 600px) and (max-width: 767px) {

  .w-90 {
    width: 85%;
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }

  .tagline {
    width: 400px;
    padding: 30px 0 20px 0;
  }

  .tagline>h2 {
    font-size: 24px;
  }

  .liner {
    height: 40px;
  }

  .welcome-text {
    margin-top: 10px;
    font: 700 22px Inter, sans-serif;
  }

  .login-form-container {
    padding: 24px;
    width: 450px;
    margin-bottom: 15px;
  }

  .content-inner {
    margin: 20px;
  }

  .login_page>.content-container>.justify-content-end {
    justify-content: center;
  }

  .login-box {
    padding: 30px;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  #root {
    padding: 0.5rem;
  }

  .content-inner {
    margin: 20px;
  }

  .login-box label#chk {
    font-size: 14px;
  }

  .forgot-password-link {
    font-size: 14px;
  }

  .liner {
    height: 40px;
    margin-right: 15px;
  }

  .logo-container {
    position: relative;
  }

  .tagline>h2 {
    font-size: 20px;
  }

  .login-form-container {
    padding: 15px 20px;
    /* width: 300px; */
    margin-bottom: 15px;
  }

  .welcome-text {
    margin-top: 15px;
    font: 700 24px Inter, sans-serif;
  }

  .instruction-text {
    font-size: 14px;
  }

  .login-form-container label {
    font-size: 14px;
  }

  .pd-0 {
    padding: 5px;
  }

  .text-right {
    margin-top: 15px;
    text-align: left;
    /* or center, or whatever alignment you prefer */
  }

  .login-box {
    padding: 25px;
  }

}

/* ============ Header ========================*/
.header {
  width: calc(100% - 400px);
  margin-top: 18px;
  margin-right: 60px;
  height: 75px;
  border-bottom: 1px solid #a3bce0;
  box-shadow: none;
  background-color: #F6F9FC;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* top: 30px;
  right: 60px; */
}

@media (min-width: 1301px) and (max-width: 1400px) {

  .header {
    width: calc(100% - 370px);

  }
}

@media (min-width: 1025px) and (max-width: 1300px) {

  .header {
    width: 92%;
    margin-right: 45px;
  }
}

@media (max-width: 1024px) {
  .header {
    position: fixed;
    width: 90%;
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .header {
    width: 90%;
    height: 70px;
    left: 20px;
    top: 0;
  }
}

/* ================================ side left bar =================================== */



.left-side-bar {
  position: absolute;
  /* height: 94vh; */
  height: calc(100% - 20px);
  border-radius: 40px;
  background: #ececf9;
  margin-top: 10px;
  margin-left: 10px;
  /*  padding: 0 15px; */
  overflow: hidden;
}

.sidebar-menu .dropdown-toggle {
  display: block;
  padding: 10px 10px 10px 35px;
  color: #676B89;
  margin: 8px 0px;
  font-size: 14px;
}

.sidebar-menu .dropdown-toggle .micon {
  left: -1px;
}

.sidebar-menu .show {
  background: #E0E1EF;
}

.sidebar-menu .dropdown-toggle .micon {
  color: #676B89;
  font-size: 18px;
}

.sidebar-menu>ul.accordion-top1>li {
  padding: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 15px;
}

.sidebar-menu>ul.accordion-bottom {
  margin-bottom: 20px;
}

.sidebar-menu>ul.accordion-bottom>li {
  padding: 6px;
  margin: 0 15px;
}

/* .sidebar-menu>ul>li:last-child {
  border-bottom: none;
} */

.sidebar-menu>ul>li>.dropdown-toggle.active {
  background-color: #0F8BFD;
  border-radius: 8px;
}

.sidebar-menu .dropdown-toggle:hover,
.sidebar-menu .show>.dropdown-toggle {
  background: #0F8BFD;
  color: #fff;
  border-radius: 8px;
}

.brand-logo {
  border-bottom: none;
}

.brand-logo a {
  margin-top: 10px;
  padding: 15px 20px;
}

.left-side-bar .close-sidebar {
  right: 20px;
}

.left-side-bar .accordion-top {
  margin-bottom: 60%;
}

@media (max-width: 1300px) {
  .left-side-bar {
    left: -301px;
  }
}

@media (max-width: 1240px) {
  .sidebar-menu .dropdown-toggle {
    margin: 18px 0px;
  }

  /* .left-side-bar .accordion-top{
    margin-bottom: 60%;
  } */
}

@media (max-width: 767px) {
  .sidebar-menu .dropdown-toggle {
    margin: 12px 0px;
  }

  /*  .left-side-bar .accordion-top{
    margin-bottom: 250px;
  } */
}

/* ================================ side left bar =================================== */

.sidebar-menu .submenu li a {
  color: #676B89;
}

.sidebar-menu .submenu li a.active,
.sidebar-menu .submenu li a:hover {
  color: #0F8BFD;
  background-color: #E0E1EF;
  font-weight: 400;
}

div#confirmation-modal {
  background: #62626285;
}

.sidebar-menu .submenu li a.active:before {
  rotate: 90deg;
  top: 40%;
  content: "\f208";
  position: absolute;
  line-height: 1;
  left: 28px;
  transform: translate(0, -50%);
  font-family: Ionicons;
}

.sidebar-menu .submenu li>a:before {
  content: "";
  /* font-weight: 400; */
  /* rotate: 90deg;
  top: 40%; */
}

.modal-header {
  border-bottom: unset !important;
}

.modal-footer {
  border-top: unset !important;
  display: flex !important;
  justify-content: space-around;
}

.modal-content {
  border-radius: 1.3rem !important;
}

.logout-logo {
  background-image: url("/src/assets/images/logout.png");
  background-repeat: no-repeat;
  /* Prevents the background image from repeating */
  background-position: center;
  /* Centers the background image */
  width: 132px;
  /* Set a width for the element */
  height: 132px;
  /* Set a height for the element */
  display: inline-block;
  /* Ensure the element is displayed correctly */
  background-color: #F4F4F4;
  border-radius: 50%;
}

/* ================ header =================== */
.header .dropdown-toggle::after {
  content: "";
  font-family: "FontAwesome";
  vertical-align: unset;
  width: auto;
  height: auto;
  border: 0 !important;
}

.font-weight-medium {
  font-weight: 500;
}

.header-right .header-search .form-group,
.sidebar-menu .submenu li {
  position: relative;
}

.header-right .header-search .search-icon {
  position: absolute;
  left: 17px;
  top: 12px;
  font-size: 20px;
  color: #777;
}

.header-right .header-search .search-input {
  padding-left: 50px;
  padding-right: 55px;
  border-radius: 25px;
}

/* .header-right {
  align-items: end;
} */

.header-left {
  align-items: start;
}

.header-left .menu-icon {
  align-items: end;
}

/* .user-notification {
  padding: 10px 5px 10px 0;
} */

.user-notification {
  padding: 0px;
}

.header-right .header-search {
  width: 50%;
  padding-left: 20px;
}

.header-left .user-info-dropdown {
  padding: 5px 0px 5px 10px;
}

.user-info-dropdown .dropdown-toggle .user-name {
  /* margin-left: 10px; */
}

.header-right .header-datetime {
  width: 50%;
  padding-left: 20px;
}

.header-right .header-datetime .datetime-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #6B7280;
}

.header-right .header-datetime .separator {
  margin: 0 10px;
  color: #6B7280;
  font-size: 10px;
}

@media (min-width: 1025px) {

  .header-right .header-search {
    display: block !important;
  }

  .header-right .header-datetime {
    /* display: block !important; */
  }
}

@media (max-width: 1024px) {
  .header-right .header-search {
    width: 100%;
  }

  .header-right .search-toggle-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .menu-icon.dw.dw-menu {
    margin: 0 20px;
  }

  .header-right .header-search {
    padding: 15px 20px;
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    display: none;
  }

  .user-info-dropdown .user-welcome {
    font-size: 24px;
  }
}

@media (max-width: 767px) {

  .user-info-dropdown .user-welcome {
    display: none;
  }

  .header-right .header-search {
    width: 100%;
  }

  .menu-icon.dw.dw-menu {
    margin: 0 8px;
  }

  .header {
    height: 75px;
  }
}

@media (max-width: 660px) {

  .header {
    height: 75px;
  }

  .menu-icon.dw.dw-menu {
    margin: 0 8px;
  }

  .header-right {
    align-items: center;
    justify-content: center;
  }

  .header-right .header-datetime {
    flex-grow: 1;
  }

  .header-right .header-datetime .center-xs {
    justify-content: center;
  }

  .header-right .header-datetime .test {
    margin-right: 10px;
  }

  .header-right .header-datetime {
    padding-left: 40px;
  }

  .header-right .header-datetime .datetime-container {
    /* display: flex; */
    /* flex-direction: column; 
     align-items: start; */
    /* font-size: 15px;
    color: #6B7280; */
  }

  /* .header-right .header-datetime .datetime-container .separator {
    display: none;
  } */
}

.cursor {
  cursor: pointer;
}

/* table classe */
.table thead th {
  font-size: 14px;
  padding-left: 0.5rem;
}

.table td,
.table th {
  padding: 4px;
}

.table-custom {
  border-collapse: collapse;
  width: 100%;
}

.table-custom th,
.table-custom td {
  border: 2px dashed #dee2e6;
  position: relative;
  /* Customize the color as needed */
}

.table-custom thead th {
  border-top: none;
  /* Remove top border */
}

.table-custom tbody td:last-child,
.table-custom tbody th:last-child {
  border-right: none;
  /* Remove right border */
}

.table-custom tbody tr:last-child td,
.table-custom tbody tr:last-child th {
  border-bottom: none;
  /* Remove bottom border */
}

.table-custom thead th:first-child,
.table-custom tbody th:first-child,
.table-custom tbody td:first-child {
  border-left: none;
  /* Remove left border */
}

/* delete modal */
.deleteModal {
  z-index: 9999;
}


/* =============== profile ====================== */

.profile-section .required::after {
  content: '*';
  color: red;
  margin-left: 5px;
  font-size: 20px;
}

.profile-section .profile-setting h4 {
  font-size: 24px;
}

.profile-photo1 {
  margin: 0 auto 15px;
}

.profileModal {
  z-index: 9999;
}

/* =============== profile ====================== */
/* pagination button */
.no-bg {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.width-paginatiion-input {
  width: 50px;
}

.popover {
  background: #fff !important;
}

a,
label {
  font-size: 14px;
}


.ti-angle-left {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  padding-right: 5px;
}

.bg-custom-eye {
  background-color: #fff;
  border: 1px solid #758190;
  color: #758190;
}

.table .thead-light th {
  color: #495057 !important;
  vertical-align: top;
}

.custom-control-label::before {
  border: 2px solid #758190 !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #0F8BFD !important;
}

/* ================================ */

.setOverlay {
  height: 150px;
  overflow-y: hidden;
}

.profile-photo1 .text-center img {
  height: 100px;
  width: 100px;
}

.btn-light {
  border: 1px solid #000;
}

.gap-custom {
  gap: 10px
}

.user-info-dropdown .dropdown-toggle .user-icon {
  background: unset !important;
  box-shadow: unset;
  margin-right: .75rem;
}

.user-info-dropdown .dropdown-toggle .user-icon img {
  height: 100%;
}

.custom-control {
  position: unset;
}

.custom-control-input {
  position: unset;
}

.tobeFilled {
  color: #FF551F;
}

/* .header-right {
  margin-top: 20px;
} */

.btn-grey {
  background: #EEEEF9;
  padding: 4px 16px;
}

.custom-file-input,
.custom-file-label,
.custom-select,
.form-control {

  height: 35px;
}

/* .form-control.customTextarea{
 font-size: 12px;
}
 */

.custom-select {
  font-size: 14px;
}

/* ========== add colleague modal ========= */
.modal-header {
  padding: 1rem 1.5rem;
}

.modal-body {
  padding: 0 1.5rem;
}

.form-check-input {
  margin-top: 0;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #fff;
  user-select: none;
  touch-action: none;
  cursor: col-resize;
}

.dragBtnHeader {
  margin-left: 5px;
  text-align: right;
  float: right;
  margin-top: 5px;
}

.left-margin {
  margin-left: -8px;
}






/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  float: right;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input.default:checked+.slider {
  background-color: #444;
}

input.primary:checked+.slider {
  background-color: #2196F3;
}

input.success:checked+.slider {
  background-color: #8bc34a;
}

input.info:checked+.slider {
  background-color: #3de0f5;
}

input.warning:checked+.slider {
  background-color: #FFC107;
}

input.danger:checked+.slider {
  background-color: #f44336;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.textarea.form-control {
  height: unset;
}

.customXscroll {
  height: 170px;
  overflow-x: auto;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #758190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  color: #758190;
}

.circlewith i{
  font-size: 1.2rem;
  color: #758190;
}

/* .circle i{
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey;
  padding: 10px;
} */


.plus-icon {
  font-size: 14px;
  color: #758190;
}


/* =========== notification ========== */

.axe-notification .btn{
    font-size: 12px;
}

.notification-list ul li a {
  display: block;
  position: relative;
  padding: 10px;
}

@media (max-width: 660px) {

  .cus-size {
    font-size: 22px;
  }

  .btn-cus-font {
    font-size: 13px;
  }
}

.custom-highlight-color {
  background-color: #c0b7b7;
}

.btn-logout {
  background-color: #FF551F;
  color: #fff;
}